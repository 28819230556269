import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, catchError, tap, filter, take, map } from 'rxjs/operators';
import { AuthState } from '../store';

import * as fromStore from '../store';
import * as fromRoot from '../../store';

@Injectable({
  providedIn: 'root',
})
export class CheckAuthGuard implements CanActivate {
  signedIn$: Observable<boolean> = of(false);
  signingIn$: Observable<boolean> = of(false);

  constructor(private store: Store<AuthState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore(route).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.store.select(fromStore.getAuthSignedIn),
      this.store.select(fromStore.getAuthChecked),
      this.store.select(fromStore.getAuthChecking),
      this.store.select(fromRoot.getRouterState),
    ]).pipe(
      tap(([signedIn, checked, checking, route]) => {
        if (!checking && !checked) {
          this.store.dispatch(fromStore.CheckAuth());
        }
      }),
      map(([signedIn, checked, checking]) => true),
      filter(loaded => loaded),
      take(1)
    );
  }
}

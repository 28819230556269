import { createSelector } from '@ngrx/store';

import * as fromRoot from '..';
import * as fromFeature from '../reducers';
import * as fromMenu from '../reducers/menu.reducer';

export const getAllMenuState = createSelector(fromFeature.getMenuState, (state: fromFeature.MenuState) => state.entries);

export const getMenuEntities = createSelector(getAllMenuState, fromMenu.getMenuEntities);
export const getMenuLoaded = createSelector(getAllMenuState, fromMenu.getMenuLoaded);
export const getMenuLoading = createSelector(getAllMenuState, fromMenu.getMenuLoading);

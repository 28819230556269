<div class="overflow-hidden mat-elevation-z8" [class.rounded]="!auto.showPanel" [class.rounded-top]="auto.showPanel">
  <div class="" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-icon matPrefix>search</mat-icon>

      <!--<span matPrefix>
        @for (item of tabItems(); track $index) {
          {{ item }}&nbsp;/&nbsp;
        }
      </span>-->
      <input
        #queryInput
        matInput
        type="search"
        [placeholder]="'tenant-switcher:search-placeholder' | fromDictionary"
        [formControl]="queryControl"
        (keyup.tab)="tab()"
        (keydown.backspace)="backspace()"
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="origin"
        (focus)="queryInput.select()"
      />
    </mat-form-field>
  </div>
  <mat-autocomplete
    (optionSelected)="activate($event)"
    class="overflow-auto custom-scrollbar"
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
  >
    @for (tenant of filteredTenants(); track tenant.id; let last = $last) {
      <mat-option [value]="tenant.id">
        {{ tenant.name }}
      </mat-option>
    }

    @if (filteredTenants().length === 0 && queryInput.value) {
      <mat-option class="empty-state" disabled>No results found</mat-option>
    }
  </mat-autocomplete>
</div>

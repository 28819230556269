import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import * as MenuActions from '../actions';
import { MenuService } from '../../services/menu.service';
import { of } from 'rxjs';
import { MenuEntry } from '../../models';

export const loadMenu$ = createEffect(
  (actions$ = inject(Actions), service = inject(MenuService)) => {
    return actions$.pipe(
      ofType(MenuActions.LoadMenu),
      switchMap(() =>
        service.loadMenu().pipe(
          map(entries => MenuActions.LoadMenuEntriesSuccess({ entries })),
          catchError(error => of(MenuActions.LoadMenuFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const loadMenuEntriesSuccess$ = createEffect(
  (actions$ = inject(Actions), service = inject(MenuService)) => {
    return actions$.pipe(
      ofType(MenuActions.LoadMenuEntriesSuccess),
      tap(({ entries }) => {
        const entities = entries.reduce((dictEntries: { [identifier: string]: MenuEntry }, entry: MenuEntry) => {
          return {
            ...dictEntries,
            [entry.identifier.toUpperCase()]: entry,
          };
        }, {});
        service.menu = entities;
      }),
      map(({ entries }) => MenuActions.LoadMenuSuccess({ entries }))
    );
  },
  { functional: true }
);

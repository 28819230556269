<!--<div class="d-flex align-items-center gap-3">
  <mat-icon>sprint</mat-icon>

  <div class="d-flex flex-column align-items-start">
    <span class="flex-grow-1">
      {{ result().title }}
    </span>
    <span class="chip chip--primary chip--xs">{{ result().subTitle }}</span>
  </div>
</div>-->

<div class="d-flex align-items-center gap-3">
  <mat-icon class="text-primary">tenancy</mat-icon>

  <span class="chip chip--primary flex-shrink-0 w-auto">
    <span>{{ result().title }}</span>
  </span>

  <span class="flex-grow-1">
    {{ result().subTitle }}
  </span>
</div>

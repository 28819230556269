import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, ElementRef, HostListener, inject, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { distinctUntilChanged } from 'rxjs';
import { CmsTenant } from 'src/app/auth/models';
import { SwitchTenant } from 'src/app/auth/store';

@Component({
  selector: 'app-tenant-switcher',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    MatListModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CdkListboxModule,
    MatChipsModule,
    MatProgressBarModule,
    NgClass,
    FromDictionaryPipe,
  ],
  templateUrl: './tenant-switcher.component.html',
  styleUrl: './tenant-switcher.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantSwitcherComponent {
  private store = inject(Store);
  private dlg = inject(DialogRef);
  private dlgData = inject(DIALOG_DATA);

  queryControl = new FormControl<string>('');
  queryInput = viewChild<ElementRef<HTMLInputElement>>('queryInput');
  queryValue = toSignal(this.queryControl.valueChanges.pipe(distinctUntilChanged()));

  filteredTenants = computed(() => {
    const query = this.queryValue();
    if (!query) {
      return this.tenants;
    }
    return this.tenants.filter(tenant => tenant.name.toLowerCase().includes(query.toLowerCase()));
  });

  tenants: CmsTenant[];

  @HostListener('keydown.escape', ['$event'])
  close() {
    this.dlg.close();
  }

  constructor() {
    this.tenants = this.dlgData.tenants;
    setTimeout(() => {
      this.queryInput()?.nativeElement.select();
    });
  }

  activate(event: MatAutocompleteSelectedEvent) {
    const tenantId = event.option.value as string;
    if (!tenantId) {
      return;
    }
    this.store.dispatch(SwitchTenant({ tenantId }));
    this.dlg.close();
  }

  tab() {
    //this.tabItems.set([...this.tabItems(), this.queryValue()!]);
    //this.queryControl.setValue('');
  }
  backspace() {
    //console.log(this.queryControl.value);
    //if (this.queryControl.value === '') {
    //  this.tabItems.set([...this.tabItems().slice(0, -1)]);
    //}
  }
}

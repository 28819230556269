import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Signal,
  computed,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';
import { LoadSearchResults, getGroupedSearchResults, getSearchQuery, getSearchResultsLoading } from '../../store';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule, NgClass } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SearchResult } from '../../models';
import { searchResultComponents } from '..';
import { IconComponent } from '@teamfoster/sdk/icon';
import { SwitchTenant } from 'src/app/auth/store';
import { CmsTenant } from 'src/app/auth/models';

@Component({
  selector: 'app-command-palette',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    MatListModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CdkListboxModule,
    MatChipsModule,
    MatProgressBarModule,
    NgClass,
    CommonModule,
    IconComponent,
    ...searchResultComponents,
  ],
  templateUrl: './command-palette.component.html',
  styleUrl: './command-palette.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandPaletteComponent {
  private store = inject(Store);
  private dlg = inject(DialogRef);
  private dlgData = inject(DIALOG_DATA);

  results$ = this.store.selectSignal(getGroupedSearchResults);
  query$ = this.store.selectSignal(getSearchQuery);
  loading$ = this.store.selectSignal(getSearchResultsLoading);

  selectedItem = signal<any>(null);
  selectedFilter = signal<string>('');

  tabItems = signal<string[]>([]);

  queryControl = new FormControl<string>(this.query$());
  queryInput = viewChild<ElementRef<HTMLInputElement>>('queryInput');
  queryValue = toSignal(this.queryControl.valueChanges.pipe(distinctUntilChanged()));

  combinedResults: Signal<{ type: string; results: SearchResult[] }[]> = computed(() => {
    const results = this.results$();
    const tenants: CmsTenant[] = this.dlgData.tenants;
    console.log(this.dlgData);
    return [
      { type: 'Tenant', results: tenants.map(item => ({ id: item.id, type: 'Tenant', title: item.name }) as SearchResult) },
      ...results,
    ];
  });

  filteredResults = computed(() => {
    const results = this.combinedResults();
    const filter = this.selectedFilter();

    return results.filter(result => result.type.indexOf(filter) > -1);
  });

  valueEffect = effect(
    () => {
      if (this.queryValue()) {
        this.search(this.queryValue()!);
      }
    },
    { allowSignalWrites: true }
  );

  @HostListener('keydown.escape', ['$event'])
  close() {
    this.dlg.close();
  }

  constructor() {
    setTimeout(() => {
      this.queryInput()?.nativeElement.select();
    });
  }

  activate(item: MatAutocompleteSelectedEvent) {
    var selectedResult = item.option.value as SearchResult;
    console.log(selectedResult);
    switch (selectedResult.type) {
      case 'Tenant':
        this.store.dispatch(SwitchTenant({ tenantId: `${selectedResult.id}` }));
        this.dlg.close();
        break;
    }
  }

  search(query: string) {
    if (!query) return;
    this.store.dispatch(LoadSearchResults({ query }));
  }

  tab() {
    this.tabItems.set([...this.tabItems(), this.queryValue()!]);
    this.queryControl.setValue('');
  }
  backspace() {
    console.log(this.queryControl.value);
    if (this.queryControl.value === '') {
      this.tabItems.set([...this.tabItems().slice(0, -1)]);
    }
  }
}

import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchResult } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SearchResultsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getSearchResults(q: string) {
    return this.http.get<SearchResult[]>(`${this.BASE_URL}api/search?q=${q}`);
  }
}

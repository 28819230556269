import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { CmsUser } from 'src/app/auth/models';
import { SignOut } from 'src/app/auth/store';
import { SetTheme } from 'src/app/store/actions/theme.action';

@Component({
  selector: 'app-side-nav-meta-menu',
  standalone: true,
  imports: [MatTooltip, RouterLink, MatIconModule, MatMenuModule, MatButtonModule, NgClass, FromDictionaryPipe],
  templateUrl: './side-nav-meta-menu.component.html',
  styleUrl: './side-nav-meta-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavMetaMenuComponent {
  private langSrv = inject(LanguageService);
  private store = inject(Store);
  triggerTenantSwitcher = output<void>();
  user = input.required<CmsUser>();
  hasTenants = input<boolean>();
  theme = input<string>();
  cssClasses = input<string>();
  closeOverlay = output<void>();

  shortcutTenant() {
    // return for windows ctrl + p and for mac cmd + p
    const key = navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? '⌘' : 'Ctrl + ';
    return ` (${key}P)`;
  }

  signOut() {
    this.store.dispatch(SignOut());
  }
  setTheme(currentTheme: string) {
    this.store.dispatch(SetTheme({ theme: currentTheme === 'dark' ? 'light' : 'dark' }));
  }
}

import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromMenu from './menu.reducer';

export const featureKey = 'menu';

export interface MenuState {
  entries: fromMenu.MenuState;
}

export const reducers: ActionReducerMap<MenuState> = {
  entries: fromMenu.MenuReducer,
};

export const getMenuState = createFeatureSelector<MenuState>(featureKey);

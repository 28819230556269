import * as MenuActions from '../actions/menu.action';
import { MenuEntry } from '../../models';
import { Action, createReducer, on } from '@ngrx/store';

export interface MenuState {
  entities: { [identifier: string]: MenuEntry };
  loaded: boolean;
  loading: boolean;
}

export const initialState: MenuState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const MenuReducer = createReducer(
  initialState,
  on(MenuActions.LoadMenuSuccess, (state: MenuState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(MenuActions.LoadMenuEntriesSuccess, (state: MenuState, { entries }) => {
    const entities = entries.reduce(
      (dictEntries: { [identifier: string]: MenuEntry }, entry: MenuEntry) => {
        return {
          ...dictEntries,
          [entry.identifier.toUpperCase()]: entry,
        };
      },
      {
        ...state.entities,
      }
    );

    return {
      ...state,
      entities,
    };
  }),
  on(MenuActions.LoadMenuSuccess, (state: MenuState, { entries }) => {
    return {
      ...state,
      loading: false,
      loaded: true,
    };
  }),
  on(MenuActions.LoadMenuFail, (state: MenuState, { error }) => {
    return {
      ...state,
      loading: false,
    };
  })
);

export const getMenuEntities = (state: MenuState) => state.entities;
export const getMenuLoading = (state: MenuState) => state.loading;
export const getMenuLoaded = (state: MenuState) => state.loaded;

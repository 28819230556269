<ul class="meta-menu" [ngClass]="cssClasses()">
  @if (hasTenants()) {
    <li>
      <button
        mat-icon-button
        (click)="closeOverlay.emit(); triggerTenantSwitcher.emit()"
        [matTooltip]="('navigation:switch-tenant' | fromDictionary) + shortcutTenant()"
        [matTooltipPosition]="'right'"
        [matTooltipClass]="'tooltip-primary'"
        color="tonal"
      >
        <mat-icon class="icon-secondary-fixed-variant">exit_to_app</mat-icon>
      </button>
    </li>
  }

  <li>
    <a
      mat-icon-button
      [routerLink]="['/', 'settings']"
      [matTooltip]="'general:settings' | fromDictionary"
      [matTooltipPosition]="'right'"
      [matTooltipClass]="'tooltip-primary'"
      color="tonal"
      (click)="closeOverlay.emit()"
    >
      <mat-icon class="icon-secondary-fixed-variant">settings</mat-icon>
    </a>
  </li>

  <li>
    <button class="user-thumb my-2 mx-auto" [matMenuTriggerFor]="accountMenu">
      <div class="user-thumb__letters">
        <span>{{ user().firstName.charAt(0) }}</span>
        <span>{{ user().lastName.charAt(0) }}</span>
      </div>

      <!-- <img class="w-100" [src]="user.avatar" [alt]="user.firstName" /> -->
    </button>

    <mat-menu #accountMenu="matMenu">
      @if (theme(); as theme) {
        <button mat-menu-item (click)="setTheme(theme)">
          <mat-icon class="tab-button__icon">{{ theme === 'dark' ? 'light' : 'dark' }}_mode</mat-icon>
          <span>{{ (theme === 'dark' ? 'navigation:light-theme' : 'navigation:dark-theme') | fromDictionary }}</span>
        </button>
      }

      <button mat-menu-item (click)="signOut()">
        <mat-icon class="tab-button__icon">logout</mat-icon>
        <span>{{ 'navigation:sign-out' | fromDictionary }}</span>
      </button>
    </mat-menu>
  </li>
</ul>

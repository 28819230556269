<div
  class="command-palette overflow-hidden mat-elevation-z8 bg-primary-subtle"
  [class.rounded]="!auto.showPanel"
  [class.rounded-top]="auto.showPanel"
>
  <div class="" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-icon matPrefix>search</mat-icon>

      <span matPrefix>
        @for (item of tabItems(); track $index) {
          {{ item }}&nbsp;/&nbsp;
        }
      </span>
      <input
        #queryInput
        matInput
        type="search"
        placeholder="Search..."
        [formControl]="queryControl"
        (keyup.tab)="tab()"
        (keydown.backspace)="backspace()"
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="origin"
        (focus)="queryInput.select()"
      />
    </mat-form-field>
    @if (loading$()) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
    <div class="p-2 d-flex gap-1">
      <button [class.active]="selectedFilter() === ''" (click)="selectedFilter.set('')" class="chip chip--primary" selected>
        <mat-icon *ngIf="selectedFilter() === ''">check</mat-icon>
        All
      </button>
      <button [class.active]="selectedFilter() === 'Tenants'" (click)="selectedFilter.set('Tenants')" class="chip chip--primary">
        <mat-icon *ngIf="selectedFilter() === 'Tenants'">check</mat-icon>
        Projecten
      </button>
    </div>
  </div>
  <mat-autocomplete
    (optionSelected)="activate($event)"
    class="bg-primary-subtle rounded-bottom overflow-auto command-palette-results-panel custom-scrollbar"
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
  >
    @for (group of filteredResults(); track group.type; let last = $last) {
      <mat-optgroup [label]="group.type">
        @for (result of group.results; track result.id) {
          <mat-option [value]="result">
            @switch (result.type) {
              @case ('Tenant') {
                <app-search-result-tenant [result]="result"></app-search-result-tenant>
              }
              @default {
                <app-search-result [result]="result"></app-search-result>
              }
            }
          </mat-option>
        }
      </mat-optgroup>
    }

    @if (filteredResults().length === 0 && queryInput.value && !loading$()) {
      <mat-option class="empty-state" disabled>No results found</mat-option>
    }
  </mat-autocomplete>
</div>
